<template>
  
  <div class="card card-custom card-stretch gutter-b">
  
    <div class="justify-content-end d-flex mb-4">
      <a
        href="#"
        class="btn btn-primary font-weight-bolder font-size-sm"
        @click="create_form_page_clicked"
        ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('FORM.CREATE_PAGE') }}</a
      >
    </div>
    
  
    <b-table
      id="forms-page-table"
      :fields="headers"
      :items="items"
      :per-page="perPage"
      :current-page="currentPage"
      head-variant="light"
      sticky-header
      class="mh-100 table-striped"
    >


      <template #cell(validation)="row">
        <v-chip v-if="row.item.validation !== 'OK'" style="font-size: 11px; color: red; border-color: red;" outlined>
          {{ $t('FORM.PAGE_VALIDATIONS.' + row.item.validation.toUpperCase()) }}
        </v-chip>
        <v-chip v-else style="font-size: 11px; color: green; border-color: green;" outlined>
          {{ $t('FORM.PAGE_VALIDATIONS.OK') }}
        </v-chip>
      </template>

      <template #cell(actions)="row">
        <div class="justify-content-end d-flex">

          <a
            href="#"
            class="btn btn-icon btn-light btn-sm mr-3"
            @click.prevent="move_up_clicked(row.item.id)"
          >
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-arrow-up color-primary"></i>
            </span>
          </a>

          <a
            href="#"
            class="btn btn-icon btn-light btn-sm mr-3"
            @click.prevent="move_down_clicked(row.item.id)"
          >
            <span class="svg-icon svg-icon-md text-primary">
              <i class="fa fa-arrow-down color-primary"></i>
            </span>
          </a>
          
          <a href="#" class="btn btn-icon btn-light btn-sm mr-3" @click.prevent="select_form_page_clicked(row.item.id)">
            <span class="svg-icon svg-icon-md svg-icon-primary" >
              <inline-svg src="/assets/svg/Write.svg"></inline-svg>
            </span>
          </a>

          <a href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="delete_form_page_clicked(row.item.id)">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
            </span>
          </a>
        </div>
      </template>
    </b-table>
    
  </div>
  
</template>

<style lang="scss" scoped>



</style>
<script>


import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'FormsPageTable',
  props: ['items'],
  emits: ['create_form_page_clicked', 'select_form_page_clicked', 'delete_form_page_clicked', 'move_down_clicked', 'move_up_clicked'],
  mixins: [ toasts ],
  components: {  },
  methods: {
    create_form_page_clicked() {
      this.$emit('create_form_page_clicked');
    },

    select_form_page_clicked(id) {
      this.$emit('select_form_page_clicked', id);
    },

    delete_form_page_clicked(id) {
      this.$emit('delete_form_page_clicked', id);
    },

    move_up_clicked(id) {
      this.$emit('move_up_clicked', id);
    },

    move_down_clicked(id) {
      this.$emit('move_down_clicked', id);
    },

  },
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      options: [100, 150, 200],
      headers: [
        {
          key: 'name',
          label: this.$t('FORM.PAGE_NAME'),
          thClass: '',
          tdClass: 'td-contain'
        },
        {
          key: 'type',
          label: this.$t('FORM.PAGE_TYPE'),
          thClass: 'pl-7',
          tdClass: 'pl-7',
          formatter: (value) => {
            return this.$t(`FORM.PAGE_TYPES.${value.toUpperCase()}`);
          }
        },
        {
          key: 'index',
          label: this.$t('FORM.INDEX'),
          thClass: 'pl-7',
          tdClass: 'pl-7'
        },
        {
          key: 'validation',
          label: this.$t('FORM.VALIDATION'),
          thClass: 'pl-7',
          tdClass: 'pl-7',
          formatter: (value) => {
            return this.$t(`FORM.PAGE_VALIDATIONS.${value.toUpperCase()}`);
          }
        },
        {
          key: 'actions',
          label: this.$t('COMMON.ACTIONS'),
          thClass: 'text-right pr-22'
        }
      ],
      list: []
    };
  }
};
</script>
