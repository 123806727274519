<template>
  
  <div class="card card-custom card-stretch gutter-b">
  
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column"></h3>
      <div class="card-toolbar">
        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm"
          @click="create_form_clicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{ $t('FORM.CREATE') }}</a
        >
      </div>
    </div>

    <div class="card-header border-0 py-5 m-1">
      <b-pagination
        class="ml-auto mb-0 align-middle"
        v-model="currentPage"
        :total-rows="items.length"
        :per-page="perPage"
        aria-controls="advance-file-table"
        first-number
        last-number
      ></b-pagination>
    </div>
    

    <div class="card-body pt-0 table-responsive">
      <b-table
        id="advance-file-table"
        :fields="headers"
        :items="items"
        :per-page="perPage"
        :current-page="currentPage"
        sort-by="created_at"
        :sort-desc="true"
        head-variant="light"
        sticky-header
        class="mh-100 table-striped"
      >

        <template #cell(actions)="row">
          <div class="justify-content-end d-flex">
            <a class="btn btn-icon btn-light btn-sm mx-3" @click="copy_link_clicked(row.item)">
              <span class="svg-icon svg-icon-md text-primary">
                <i class="fa fa-link color-primary"></i>
              </span>
            </a>
            
            <a href="#" class="btn btn-icon btn-light btn-sm mr-3" @click.prevent="select_form_clicked(row.item.id)">
              <span class="svg-icon svg-icon-md svg-icon-primary" >
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>

            <a href="#" class="btn btn-icon btn-light btn-sm" @click.prevent="delete_form_clicked(row.item.id)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a>
          </div>
        </template>
      </b-table>
      
    </div>

    
  </div>
  
</template>

<style lang="scss" scoped>



</style>
<script>


import { get_domain_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'FormsTable',
  props: ['items', 'currentCompanyId'],
  emits: ['select_form_clicked', 'delete_form_clicked'],
  mixins: [ toasts ],
  components: {  },
  methods: {
    create_form_clicked() {
      this.$emit('create_form_clicked');
    },

    copy_link_clicked(item) {
      navigator.clipboard
        .writeText(`${get_domain_url()}/form/${item.linkstr}`)
        .then(() => {
          this.toastr('success', this.$t('COMMON.OK') , this.$t('FORM.COPIED'));
        })
        .catch(() => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('FORM.UNABLE_COPY'));
        });

    },

    select_form_clicked(id) {
      this.$emit('select_form_clicked', id);
    },

    delete_form_clicked(id) {
      this.$emit('delete_form_clicked', id);
    },

  },
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      options: [100, 150, 200],
      headers: [
        {
          key: 'name',
          label: this.$t('FORM.NAME'),
          sortable: true,
          thClass: '',
          tdClass: 'td-contain'
        },
        {
          key: 'type',
          label: this.$t('FORM.TYPE'),
          sortable: true,
          thClass: 'pl-7',
          tdClass: 'pl-7',
          formatter: (value) => {
            return this.$t(`FORM.TYPES.${value.toUpperCase()}`);
          }
        },
        {
          key: 'created_at',
          label: this.$t('FORM.CREATED_AT'),
          sortable: true,
          thClass: 'pl-7',
          tdClass: 'pl-7'
        },
        {
          key: 'actions',
          label: this.$t('COMMON.ACTIONS'),
          thClass: 'text-right pr-22'
        }
      ],
      list: []
    };
  }
};
</script>
