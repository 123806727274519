import { render, staticRenderFns } from "./FormRuleTable.vue?vue&type=template&id=7e5c6a25&scoped=true"
import script from "./FormRuleTable.vue?vue&type=script&lang=js"
export * from "./FormRuleTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e5c6a25",
  null
  
)

export default component.exports